<template>
  <b-card no-body>
    <div class="m-2">
      <b-form @submit.prevent>
        <b-row>
          <b-col md="4">
            <label for="inline-form-custom-select-pref">KYC Status</label>
            <v-select
              v-model="kycStatusFillter"
              :options="statusOption"
              placeholder="All"
              :clearable="false"
              :searchable="false"
              :reduce="(val) => val.value"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </b-col>
          <!-- <b-col md="4">
            <label for="inline-form-custom-select-pref">Mode:</label>
            <v-select
              v-model="modeFilter"
              :options="modeOptions"
              placeholder="All"
              :clearable="false"
              :searchable="false"
              :reduce="(val) => val.value"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </b-col>
          <b-col md="4">
            <label for="inline-form-custom-select-pref">Search Key:</label>
            <b-form-input
              v-model="searchKeyFilter"
              class="d-inline-block mb-1"
              placeholder="Search..."
              autocomplete="off"
            />
          </b-col> -->
          <b-col md="4">
            <label for="inline-form-custom-select-pref">Document Type</label>
            <v-select
              v-model="documentTypeFilter"
              :options="documentTypeOption"
              placeholder="Document Type"
              :clearable="false"
              :searchable="false"
              :reduce="(val) => val.value"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </b-col>
          <b-col md="4">
            <label
              class="mr-sm-1"
              for="inline-form-custom-select-pref"
            >Create Time:</label>
            <date-picker
              :time-range-default="timeRangeDefault"
              @TimeRange="getTimeRange"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md="4">
            <label for="inline-form-custom-select-pref">Search Terms:</label>
            <b-input-group>
              <b-input-group-prepend>
                <b-dropdown
                  :text="modeFilter.label"
                  variant="outline-primary"
                >
                  <b-dropdown-item
                    v-for="(item, index) in modeOptions"
                    :key="index"
                    @click="modeOptionChange(item)"
                  >
                    {{ item.label }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-input-group-prepend>
              <b-form-input
                v-model="searchKeyFilter"
                placeholder="Search..."
                autocomplete="off"
              />
            </b-input-group>
          </b-col>
          <!-- <b-col md="4">
            <label
              for="inline-form-custom-select-pref"
            >Time spent on photo upload:</label>
            <v-select
              v-model="timeSpendFillter"
              :options="timeSpendOptions"
              placeholder="All"
              :clearable="false"
              :searchable="false"
              :reduce="(val) => val.value"
            />
          </b-col> -->
        </b-row>
        <b-row>
          <b-col class="text-center">
            <b-button
              variant="primary"
              class="mr-2 b-col-top"
              @click="search"
            >Search</b-button>
            <b-button
              variant="success"
              class="mr-2 b-col-top"
              @click="reset"
            >Reset</b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <b-table
      id="userCenterListTable"
      ref="refKycTable"
      :items="userCenterList"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      class="position-relative"
    >
      <!-- Column: user_id -->
      <template #cell(user_id)="data">
        <p>
          USER ID:
          <b-link
            v-hasBtn="`link_user_list`"
            :to="{
              path: '/usercenter/user-detail',
              query: { id: data.item.user_info.user_id, timezone: timeZone },
            }"
            class="font-weight-bold"
            target="_blank"
          >
            {{ data.item.user_info.user_id }}
          </b-link>
          <span v-hasBtn="`no_user_list`">{{ data.item.user_info.user_id }}</span>
        </p>
        <p>NAME:{{ data.item.user_info.name }}</p>
        <p>NAT ID:{{ data.item.user_info.document_id }}</p>
      </template>

      <!-- Column: id_document_photo -->
      <template #cell(id_document_photo)="data">
        <div
          class="id-card-box"
          @click="clickPictrue"
        >
          <img
            alt="img"
            class="id-card-image"
            :src="`${data.item.user_kyc.domain}${data.item.user_kyc.id_document_photo}`"
          >
          <div
            class="id-card-bg"
            :style="{'background-color': formatStatusBg(data.item.user_kyc.kyc_status)}"
          />
          <div class="id-card-text">
            {{ formatKycStatustext(data.item.user_kyc.kyc_status) }}
          </div>
        </div>
      </template>

      <!-- Column: id_selfie -->
      <template #cell(id_selfie)="data">
        <div
          class="id-card-box"
          @click="clickPictrue"
        >
          <img
            alt="img"
            class="id-card-image"
            :src="`${data.item.user_kyc.domain}${data.item.user_kyc.id_selfie}`"
          >
          <div
            class="id-card-bg"
            :style="{'background-color': formatStatusBg(data.item.user_kyc.kyc_status)}"
          />
          <div class="id-card-text">
            {{ formatKycStatustext(data.item.user_kyc.kyc_status) }}
          </div>
        </div>
      </template>

      <!-- address_pic -->
      <template #cell(address_pic)="data">
        <div
          v-if="data.item.user_kyc.address_pic"
          class="id-card-box"
          @click="clickPictrue"
        >
          <img
            alt="img"
            class="id-card-image"
            :src="`${data.item.user_kyc.domain}${data.item.user_kyc.address_pic}`"
          >
          <div
            class="id-card-bg"
            :style="{'background-color': formatStatusBg(data.item.user_kyc.address_status)}"
          />
          <div class="id-card-text">
            {{ formatKycStatustext(data.item.user_kyc.address_status) }}
          </div>
        </div>
        <div
          v-if="!data.item.user_kyc.address_pic"
          class="id-card-box"
        >
          <div
            class="id-card-bg"
            :style="{'background-color': formatStatusBg(data.item.user_kyc.address_status)}"
          />
          <div class="id-card-text">
            {{ formatKycStatustext(data.item.user_kyc.address_status) }}
          </div>
        </div>
      </template>

      <!-- fund_source_pic -->
      <template #cell(fund_source_pic)="data">
        <div
          v-if="data.item.user_kyc.fund_source_pic"
          class="id-card-box"
          @click="clickPictrue"
        >
          <img
            alt="img"
            class="id-card-image"
            :src="`${data.item.user_kyc.domain}${data.item.user_kyc.fund_source_pic}`"
          >
          <div
            class="id-card-bg"
            :style="{'background-color': formatStatusBg(data.item.user_kyc.fund_source_status)}"
          />
          <div class="id-card-text">
            {{ formatKycStatustext(data.item.user_kyc.fund_source_status) }}
          </div>
        </div>
        <div
          v-if="!data.item.user_kyc.fund_source_pic"
          class="id-card-box"
        >
          <div
            class="id-card-bg"
            :style="{'background-color': formatStatusBg(data.item.user_kyc.fund_source_status)}"
          />
          <div class="id-card-text">
            {{ formatKycStatustext(data.item.user_kyc.fund_source_status) }}
          </div>
        </div>
      </template>

      <!-- Column: Check -->
      <template #cell(Check)="data">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-hasBtn="`kyc_check`"
          variant="success"
          class="kyc_check_button"
          @click="kycCheck(data.item)"
        >
          <span>Check</span>
          <img
            src="@/assets/images/usercenter/kyc_check.png"
            alt="check"
            class="kyc_check_image"
          >
        </b-button>
      </template>

      <!-- Column: kyc_status -->
      <template #cell(kyc_status)="data">
        <span class="text-nowrap">
          {{ formatKycStatustext(data.item.user_kyc.kyc_status) }}
        </span>
      </template>

      <!-- Column: quick_re_upload -->
      <template #cell(quick_re_upload)="data">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-hasBtn="`kyc_check`"
          variant="outline-primary"
          class="re-upload-button"
          :disabled="data.item.user_kyc.kyc_status === 5"
          @click="changeReUpload(data.item.user_info.user_id)"
        >
          Re-Upload
        </b-button>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span
            class="text-muted"
          >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
            {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalInvoices"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <!-- modal -->
    <b-modal
      ref="kyc-check"
      hide-footer
      modal-class="modal-primary"
      centered
      title="KYC Check"
      size="lg"
      :no-close-on-backdrop="true"
      @ok="handleKycCheckOk"
    >
      <kyc-check-component
        :user-id="currentUserId"
        :name="currentName"
        :document-type="currentDocumentType"
        :document-id="currentDocumentId"
        :street="currentStreet"
        :city="currentCity"
        :salary="currentSalary"
        :id-document="currentIdDocument"
        :id-selfie="currentIdSelfie"
        :address-pic="currentAddressPic"
        :fund-source-pic="currentFundSourcePic"
        :key-status="currentKeyStatus"
        :fund-source-status="fundSourceStatus"
        :address-status="addressStatus"
        @afterKycCheck="afterKycCheck"
      />
    </b-modal>
  </b-card>
</template>
<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BButton,
  BLink,
  BModal,
  VBModal,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  defineComponent,
  reactive,
  toRefs,
  watch,
  computed,
  getCurrentInstance,
  onMounted,
  ref,
} from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex'
import Ripple from 'vue-ripple-directive'
import {
  setDatetimeDefault,
  handlerTimestamp,
  formatDocumentId2,
  showToast,
} from '@/libs/utils'
import DatePicker from '@/component/Datepicker.vue'
import kycCheckComponent from './KycCheck.vue'

const { mapState: mapStateWallet } = createNamespacedHelpers('wallet')
const useKycCheck = () => {
  const { proxy } = getCurrentInstance()
  const state = reactive({
    // search
    modeFilter: { label: 'User ID', value: 3 },
    searchKeyFilter: '',
    documentTypeFilter: 0,
    kycStatusFillter: 0,
    createTimeFillter: '',
    // timeSpendFillter: 0,
    timeRangeDefault: [],
    timeRange: [],
    // 1 - transaction_id,
    // 2 - channel_id(thirdparty_id/partner_id),
    // 3 - uuid,
    // 4 - phone,
    // 5 - document_id,
    // 6 - email,
    // 7 - name,
    // 8 - merchant_id
    modeOptions: [
      { label: 'User ID', value: 3 },
      { label: 'Phone number', value: 4 },
      { label: 'Email', value: 6 },
      { label: 'Document ID', value: 5 },
      { label: 'Name', value: 7 },
    ],
    documentTypeOption: [
      { label: 'All', value: 0 },
      { label: 'ID Doc', value: 'NationalID' },
      // { label: 'ID Selfie', value: 2 },
      { label: 'Address ID', value: 'Address' },
      { label: 'Source of Funds', value: 'FundSource' },
    ],
    statusOption: [
      { label: 'All', value: 0 },
      { label: 'Upload', value: 3 },
      { label: 'Verified', value: 4 },
      { label: 'Re-upload request', value: 5 },
      // { label: 'No Upload', value: 0 },
      // { label: 'Permanently blocked', value: 2 },
    ],
    timeSpendOptions: [
      { label: 'All', value: 0 },
      { label: 'Long time (60 sec +)', value: 1 },
      { label: 'Average (30-60 sec)', value: 2 },
      { label: 'Short time (30 sec - ) ', value: 3 },
    ],
    // table
    refKycTable: '',
    perPage: 10,
    totalInvoices: 0,
    currentPage: 1,
    perPageOptions: [10, 25, 50, 100],
    tableColumns: [
      { key: 'user_id', label: 'User Details' },
      { key: 'id_document_photo', label: 'ID Document Photo 1' },
      { key: 'id_selfie', label: 'ID selfie' },
      { key: 'address_pic', label: 'Address ID' },
      { key: 'fund_source_pic', label: 'Source of funds' },
      { key: 'Check', label: 'Check' },
      // { key: 'kyc_status', label: 'KYC STATUS' },
      // { key: 'quick_re_upload', label: 'Quick Re-upload' },
    ],
    userCenterList: [],
    currentUserId: '',
    currentName: '',
    currentDocumentType: '',
    currentDocumentId: '',
    currentStreet: '',
    currentCity: '',
    currentSalary: '',
    currentIdDocument: '',
    currentIdSelfie: '',
    currentAddressPic: '',
    currentFundSourcePic: '',
    currentKeyStatus: '',
    fundSourceStatus: '',
    addressStatus: '',
  })
  state.timeRangeDefault = []
  state.timeRange = []
  // *取消默认时间*
  // state.timeRangeDefault = setDatetimeDefault(1, 1)
  // state.timeRange = handlerTimestamp(
  //   state.timeRangeDefault[0],
  //   state.timeRangeDefault[1],
  //   'America/Sao_Paulo',
  // )
  const {
    modeFilter,
    searchKeyFilter,
    documentTypeFilter,
    kycStatusFillter,
    createTimeFillter,
    // timeSpendFillter,
    timeRangeDefault,
    timeRange,
    modeOptions,
    documentTypeOption,
    statusOption,
    timeSpendOptions,
    refKycTable,
    perPage,
    totalInvoices,
    currentPage,
    perPageOptions,
    tableColumns,
    userCenterList,
    currentUserId,
    currentName,
    currentDocumentType,
    currentDocumentId,
    currentStreet,
    currentCity,
    currentSalary,
    currentIdDocument,
    currentIdSelfie,
    currentAddressPic,
    currentFundSourcePic,
    currentKeyStatus,
    fundSourceStatus,
    addressStatus,
  } = toRefs(state)
  const dataMeta = computed(() => {
    const localItemsCount = state.refKycTable
      ? state.refKycTable.localItems.length
      : 0
    return {
      from: state.perPage * (state.currentPage - 1) + (localItemsCount ? 1 : 0),
      to: state.perPage * (state.currentPage - 1) + localItemsCount,
      of: state.totalInvoices,
    }
  })
  const getTimeRange = time => {
    state.timeRange = time
  }
  const fetchKycCheckList = () => {
    // proxy.$showLoading()
    const params = {
      key: {
        key: state.searchKeyFilter,
        model: state.modeFilter.value,
      },
      status: state.kycStatusFillter,
      time_scope: {
        start_date: '',
        end_date: '',
      },
      // upload_spent_time: state.timeSpendFillter,
      page_no: state.currentPage,
    }
    if ((params.key.model === 4 || params.key.model === 5) && params.key.key) {
      // 4为phone 5为documentid
      params.key.key = params.key.key.replace(/[^\d]/g, '') // 过滤后剩下数字
    }
    if (timeRange.value.length > 0) {
      [params.time_scope.start_date, params.time_scope.end_date] = state.timeRange
    } else {
      params.time_scope.start_date = null
      params.time_scope.end_date = null
    }
    if (state.documentTypeFilter === 0) {
      params.type = ''
    } else {
      params.type = state.documentTypeFilter
    }
    proxy.$showLoading()
    proxy.$http
      .post('/api/v2/admin/user_kyc/list', params)
      .then(response => {
        const { code, message, data } = response.data
        if (code === 200) {
          state.userCenterList = data.list.map(user => {
            // eslint-disable-next-line no-param-reassign
            if (!user.user_info) { user.user_info = {} }
            // eslint-disable-next-line no-param-reassign
            if (!user.user_kyc) { user.user_kyc = {} }
            return user
          })
          // data.list.forEach(value => {
          //   const bean = {}
          //   if (value.user_info) {
          //     Object.keys(value.user_info).forEach(info => { bean[info] = value.user_info[info] })
          //   }
          //   if (value.user_kyc) {
          //     Object.keys(value.user_kyc).forEach(info => { bean[info] = value.user_kyc[info] })
          //   }
          //   state.userCenterList.push(bean)
          // })
          state.totalInvoices = data.pagination.total_count
        } else {
          showToast(proxy, 'warning', message)
        }
        proxy.$hideLoading()
      })
      .catch(error => {
        proxy.$hideLoading()
        showToast(proxy, 'warning', error.message)
      })
  }
  const search = () => {
    if (state.currentPage !== 1) {
      state.currentPage = 1
    } else {
      fetchKycCheckList()
    }
  }
  const reset = () => {
    state.modeFilter = { label: 'User ID', value: 3 }
    state.searchKeyFilter = ''
    state.documentTypeFilter = 0
    state.kycStatusFillter = 0
    state.timeRangeDefault = []
    state.timeRange = []
    state.currentPage = 1
  }
  watch(currentPage, value => {
    state.currentPage = value
    fetchKycCheckList()
  })
  const changeReUpload = userId => {
    proxy.$showLoading()
    const params = {
      user_id: userId,
      kyc_status: 5,
    }
    proxy.$http
      .post('/api/v2/admin/user_kyc/check', params)
      .then(response => {
        const { code, message } = response.data
        if (code === 200) {
          showToast(proxy, 'success', 'Success')
          fetchKycCheckList()
        } else {
          showToast(proxy, 'warning', message)
        }
        proxy.$hideLoading()
      })
      .catch(error => {
        proxy.$hideLoading()
        showToast(proxy, 'warning', error.message)
      })
  }
  const kycCheck = item => {
    state.currentUserId = item.user_info.user_id
    state.currentName = item.user_info.name
    state.currentDocumentType = item.user_info.document_type_text
    state.currentDocumentId = formatDocumentId2(
      item.user_info.document_id,
      item.user_info.document_type_text,
    )
    state.currentStreet = item.user_info.street
    state.currentCity = item.user_info.city
    state.currentSalary = item.user_info.salary
    state.currentIdDocument = `${item.user_kyc.domain}${item.user_kyc.id_document_photo}`
    state.currentIdSelfie = `${item.user_kyc.domain}${item.user_kyc.id_selfie}`
    if (item.user_kyc.address_pic) {
      state.currentAddressPic = `${item.user_kyc.domain}${item.user_kyc.address_pic}`
    } else {
      state.currentAddressPic = ''
    }
    if (item.user_kyc.fund_source_pic) {
      state.currentFundSourcePic = `${item.user_kyc.domain}${item.user_kyc.fund_source_pic}`
    } else {
      state.currentFundSourcePic = ''
    }
    state.currentKeyStatus = item.user_kyc.kyc_status
    state.fundSourceStatus = item.user_kyc.fund_source_status
    state.addressStatus = item.user_kyc.address_status
    proxy.$refs['kyc-check'].show()
  }
  const afterKycCheck = () => {
    proxy.$refs['kyc-check'].hide()
    fetchKycCheckList()
  }
  const handleKycCheckOk = bvModalEvt => {
    bvModalEvt.preventDefault()
  }
  return {
    // 变量
    modeFilter,
    searchKeyFilter,
    documentTypeFilter,
    kycStatusFillter,
    createTimeFillter,
    // timeSpendFillter,
    timeRangeDefault,
    timeRange,
    modeOptions,
    documentTypeOption,
    statusOption,
    timeSpendOptions,
    refKycTable,
    perPage,
    totalInvoices,
    currentPage,
    perPageOptions,
    tableColumns,
    userCenterList,
    currentUserId,
    currentName,
    currentDocumentType,
    currentDocumentId,
    currentStreet,
    currentCity,
    currentSalary,
    currentIdDocument,
    currentIdSelfie,
    currentAddressPic,
    currentFundSourcePic,
    currentKeyStatus,
    fundSourceStatus,
    addressStatus,

    dataMeta,
    // 方法
    getTimeRange,
    fetchKycCheckList,
    search,
    reset,
    changeReUpload,
    kycCheck,
    afterKycCheck,
    handleKycCheckOk,
  }
}
export default defineComponent({
  setup() {
    const { proxy } = getCurrentInstance()
    const {
      modeFilter,
      searchKeyFilter,
      documentTypeFilter,
      kycStatusFillter,
      createTimeFillter,
      // timeSpendFillter,
      timeRangeDefault,
      timeRange,
      modeOptions,
      documentTypeOption,
      statusOption,
      timeSpendOptions,
      refKycTable,
      perPage,
      totalInvoices,
      currentPage,
      perPageOptions,
      tableColumns,
      userCenterList,
      currentUserId,
      currentName,
      currentDocumentType,
      currentDocumentId,
      currentStreet,
      currentCity,
      currentSalary,
      currentIdDocument,
      currentIdSelfie,
      currentAddressPic,
      currentFundSourcePic,
      currentKeyStatus,
      fundSourceStatus,
      addressStatus,
      dataMeta,
      // 方法
      getTimeRange,
      fetchKycCheckList,
      search,
      reset,
      changeReUpload,
      kycCheck,
      afterKycCheck,
      handleKycCheckOk,
    } = useKycCheck()

    const storeState = mapStateWallet(['timeZone'])
    const { timeZone } = { ...storeState }
    watch(timeZone, () => {
      // // timeRange.value = handlerTimestamp(
      // //   timeRangeDefault.value[0],
      // //   timeRangeDefault.value[1],
      // //   value,
      // // )
      // fetchKycCheckList()
    })
    onMounted(() => {
      // fetchKycCheckList()
    })
    const clickPictrue = e => {
      if (e.target.tagName.toUpperCase() === 'IMG') {
        proxy.$previewPictrue(e.target.src) // 获取到图片的src传递插件就能实现图片预览  非常的便捷灵活
      }
    }
    const modeOptionChange = item => {
      modeFilter.value = item
    }
    const formatKycStatustext = value => {
      if (value === '') return ''
      switch (value) {
        case 0:
          return 'No upload'
        // case 2:
        //   return 'Permanently blocked'
        case 3:
          return 'Upload'
        case 4:
          return 'Verified'
        case 5:
          // return 'Re-upload request'
          return 'Re-upload'
        default:
          return ''
      }
    }
    const formatStatusBg = value => {
      if (value === '') return ''
      switch (value) {
        case 0:
          return '#818181'
        case 2:
          return '#8b595b'
        case 3:
          return '#acb53c'
        case 4:
          return '#52b07c'
        case 5:
          return '#dd5158'
        default:
          return ''
      }
    }

    return {
      modeFilter,
      searchKeyFilter,
      documentTypeFilter,
      kycStatusFillter,
      createTimeFillter,
      // timeSpendFillter,
      timeRangeDefault,
      timeRange,
      modeOptions,
      documentTypeOption,
      statusOption,
      timeSpendOptions,
      refKycTable,
      perPage,
      totalInvoices,
      currentPage,
      perPageOptions,
      tableColumns,
      userCenterList,
      currentUserId,
      currentName,
      currentDocumentType,
      currentDocumentId,
      currentStreet,
      currentCity,
      currentSalary,
      currentIdDocument,
      currentIdSelfie,
      currentAddressPic,
      currentFundSourcePic,
      currentKeyStatus,
      fundSourceStatus,
      addressStatus,

      dataMeta,
      // 方法
      getTimeRange,
      search,
      reset,
      changeReUpload,
      kycCheck,
      afterKycCheck,
      handleKycCheckOk,
      clickPictrue,

      timeZone,
      modeOptionChange,

      formatKycStatustext,
      formatStatusBg,
    }
  },
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BLink,
    BModal,
    BInputGroup,
    BInputGroupPrepend,

    vSelect,
    DatePicker,

    kycCheckComponent,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
})
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.kyc_check_button {
  display: flex;
  align-items: center;
  .kyc_check_image {
    width: 20px;
    height: auto;
    margin-left: 10px;
  }
}
.id-card-box {
  width: 150px;
  height: 200px;
  position: relative;
  .id-card-image {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .id-card-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 35px;
    // background-color: #52b07c;
    opacity: 0.7;
  }
  .id-card-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 35px;
    line-height: 35px;
    color: #FFFFFF;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
  }
}

.re-upload-button {
  white-space: nowrap;
}
</style>
